import { format } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useDispatch } from 'react-redux'
import { isDayMethods } from '../../constants/adminCalendar'
import { days } from '../../constants/config'
import useDisabledDates from '../../hooks/useDisabledDates'
import {
  getAllAppointmentTimesAtDate,
  getAppointmentConfig,
  getOpeningHours
} from '../../lib/firebaseServices'
import {
  loadingTimes,
  setAvailableTimes,
  setMaxAppointmentsOnDate,
  setSelectedDate,
  setSelectedTime
} from '../../redux/calendarAndTimeSlice'
import { getAllPossibleTimesForDate } from '../../services'
import { openinghours } from '../../typings'
import Loader from '../Loader'

interface CalendarProps {}

const JustCalendar: FC<CalendarProps> = ({}) => {
  // Hooks
  const dispatch = useDispatch()
  const disabledDates = useDisabledDates() // Dates that should be greyed out in the calendar

  // Local State Definitions
  const [appointmentDuration, setAppointmentDuration] = useState<null | number>(null)
  const [concurrentPatients, setConcurrentPatients] = useState<null | number>(null)
  const [openingHours, setOpeningHours] = useState<openinghours>({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
  })

  useEffect(() => {
    const fetchNeccessaryData = async () => {
      const openingHours = await getOpeningHours()
      const { appointmentDuration, concurrentPatients } = await getAppointmentConfig()
      setConcurrentPatients(concurrentPatients)
      setAppointmentDuration(appointmentDuration)
      setOpeningHours(openingHours)
    }

    fetchNeccessaryData()
  }, [])

  const handleClickDay = async (e: Date) => {
    // User clicked on a day (param Date) in the calendar
    // appointmentDuration && concurrentPatients only clickable when already rendered, therefore expected (!)

    dispatch(loadingTimes(true))
    dispatch(setSelectedDate(e))
    // reset any previously selected time
    dispatch(setSelectedTime(null))

    // // Special case: user selected the current day
    // const selectedToday = isSameDay(e, today)

    const allPossibleTimesForDate = getAllPossibleTimesForDate(e, openingHours, appointmentDuration!)

    // By the selected day of week, get all possible appointment times
    if (allPossibleTimesForDate) {
      const { allPossibleTimes, appointmentsUntilClose, appointmentsUntilBreak } = allPossibleTimesForDate

      // If there is a break on the given day (not just open & close)
      if (appointmentsUntilBreak)
        dispatch(
          setMaxAppointmentsOnDate((appointmentsUntilClose + appointmentsUntilBreak) * concurrentPatients!)
        )
      else dispatch(setMaxAppointmentsOnDate(appointmentsUntilClose * concurrentPatients!))

      // User selected today, only show timeslots after the current time (user can't book appointment earlier in the day)
      // if (selectedToday) {
      //   const todaysAvailableTimes = allPossibleTimes.filter((n) => isAfter(n, current))
      //   dispatch(setAvailableTimes(todaysAvailableTimes))
      //   if (todaysAvailableTimes.length === 0) setDisabledDates((prev) => [...prev, today])
      //   return
      // }

      // unavailableTimes contains an array of Date/time that are already reserved for other patients
      const appointmentsAtDate = await getAllAppointmentTimesAtDate(e)

      // Check how many times value appears in array
      const getOccurrence = (array: any[], value: any) => {
        return array.filter((v) => v.getTime() === value.getTime()).length
      }

      // To display the options for the chosen date, subtract all unavailable times from the possible times
      const availableTimes = allPossibleTimes.filter((time) => {
        return getOccurrence(appointmentsAtDate, time) < concurrentPatients!
      })
      dispatch(setAvailableTimes(availableTimes))

      dispatch(loadingTimes(false))
    }
  }

  /* 

    Is any weekday (monday, tuesday, ...) disabled because office is always closed that day?

  */

  const closedDays: string[] = []
  days.forEach((weekday) => {
    type dayType = keyof typeof openingHours
    const objProperty = weekday as dayType
    const dayOpeningHours = openingHours[objProperty]

    if (dayOpeningHours) {
      const isClosed = Object.values(dayOpeningHours).every((property) => property === null)
      if (isClosed) closedDays.push(weekday)
    }
  })

  // Array of methods that identify weekday as closed day (isMonday, isTuesday, ...)
  const methodArr: ((date: Date | number) => boolean)[] = []
  closedDays.forEach((day) => {
    const index = days.indexOf(day)
    methodArr.push(isDayMethods[index])
  })

  const isClosedWeekday = (date: Date) => {
    const isClosed = methodArr.some((isClosedDay) => isClosedDay(date))
    return isClosed
  }

  return (
    <>
      {!appointmentDuration || !concurrentPatients ? (
        <Loader show />
      ) : (
        <Calendar
          tileDisabled={({ date, view }) =>
            (view === 'month' && // Block day tiles only
              disabledDates.some((disabledDate) => {
                return disabledDate === format(date, 'dd.MM.yyyy')
              })) ||
            isClosedWeekday(date)
          }
          minDate={new Date()}
          className='REACT-CALENDAR p-2'
          view='month'
          onClickDay={(e) => handleClickDay(e)}
        />
      )}
    </>
  )
}

export default JustCalendar
