import { FC } from 'react'
import { SpinnerCircular } from 'spinners-react'

interface LoaderProps {
  show: boolean
}

const Loader: FC<LoaderProps> = ({ show }) => {
  return show ? (
    <div className='relative pointer-events-none inset-0 flex justify-center items-center z-30'>
      <SpinnerCircular size={75} color='4f46ba' secondaryColor='rgba(0,0,0,0.15)' />
    </div>
  ) : null
}

export default Loader
