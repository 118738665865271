import { format, parse } from 'date-fns'
import { collection, onSnapshot, orderBy, query, where, doc, getDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { days } from '../constants/config'
import { firestore } from '../lib/firebaseConfig'
import { getOpeningHours } from '../lib/firebaseServices'

const useDisabledDates = () => {
  const [disabledDates, setDisabledDates] = useState<string[]>([])

  // only perform holiday query once
  const [madeHolidayQuery, setMadeHolidayQuery] = useState(false)
  let disabled: string[] = []

  /* 
  
    Every holiday should be a disabled date

  */

  useEffect(() => {
    const getHolidays = async () => {
      const res = await fetch('https://feiertage-api.de/api/?jahr=2022&nur_land=SH')
      const data = await res.json()

      Object.entries(data).forEach((holiday: any) => {
        const [key, { datum }] = holiday
        const date = parse(datum, 'yyyy-MM-dd', new Date())
        const formattedDate = format(date, 'dd.MM.yyyy')
        disabled.push(formattedDate)
      })

      setMadeHolidayQuery(true)
    }

    if (!madeHolidayQuery) getHolidays()
  }, [])

  /* 
  
    Every booked out day should be a disabled date

  */

  useEffect(() => {
    const q = query(collection(firestore, 'calendar'), where('bookedOut', '==', true))

    const unsub = onSnapshot(q, (snapshot) => {
      snapshot.forEach((document) => {
        disabled.push(document.id)
      })
      setDisabledDates(disabled)
    })
    return () => unsub()
  }, [])

  /* 
  
    Every closed-office-day should be a disabled date

  */

  useEffect(() => {
    const fetchOpeningHours = async () => {
      const openingHours = await getOpeningHours()
      const closedDays: string[] = []
      days.forEach((day) => {
        const isClosed = Object.values(openingHours).every((property) => property === null)
      })
    }
  }, [])

  return disabledDates
}

export default useDisabledDates
